import React from 'react';
import Products from './Products/Products';
import Solutions from './Solutions/Solutions';
import Services from './Services/Services';
import MainSection from './MainSection';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ProductPage from './Products/ProductPage';
import ServicePage from './Services/ServicePage';
import SolutionPage from './Solutions/SolutionPage';

const Main = () => {
    return (
        <>
        <section className='flex flex-col' >
            <Routes>
                <Route path="/" element={<MainSection />} /> 
            </Routes>
            <Routes>
                <Route path="/" element={<Products />} />
                <Route path="/product/:id" element={<ProductPage />} />
            </Routes>
            <Routes>
                <Route path="/" element={<Solutions />} />
                <Route path="/solution/:id" element={<SolutionPage />} />
            </Routes>
            <Routes>
                <Route path="/" element={<Services />} />
                <Route path="/service/:id" element={<ServicePage />} />
            </Routes>
        </section>
        </>
    );
};

export default Main;



<Link href="/product/:id">Product</Link>