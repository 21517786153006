import React, { useState } from 'react';
import servicesData from './ServiceData';
import { Link } from 'react-router-dom';

const ServiceCard = ({ service }) => {
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg border border-gray-200 hover:scale-105">
        <img className="w-full h-[200px] rounded-md" src={service.image} alt={service.name} />
        <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{service.name}</div>
            <p className="text-gray-700 text-base line-clamp-4">{service.description}</p>
            <Link to={`/service/${service.id}`} className="flex mt-2 text-sm text-blue-500 hover:text-blue-700 items-end hover:underline">
                Read More
            </Link>
        </div>
        </div>
    );
    };

    const ServicesSection = () => {
    const [showAll, setShowAll] = useState(false);

    const servicesToDisplay = showAll ? servicesData : servicesData.slice(0, 4);

    return (
        <section className="container px-4 md:px-28 mx-auto my-8">
        <div className="text-3xl text-red-700  font-bold mb-4 md:mb-8">Services<div className="w-full h-0.5 bg-gray-300 mt-2"></div></div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {servicesToDisplay.map(service => (
            <ServiceCard key={service.id} service={service} />
            ))}
        </div>
        {!showAll ? (
        <button onClick={() => setShowAll(true)} className="block mx-auto mt-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            See All Services
            </button>
        ) : (
            <button onClick={() => setShowAll(false)} className="block mx-auto mt-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            Hide Services
        </button>
        )}
        </section>
    );
};

export default ServicesSection;

