import React from 'react';
import { useParams } from 'react-router-dom';
import servicesData from './ServiceData';

function ServicePage() {
    const { id } = useParams();
    const service = servicesData.find(service => service.id === parseInt(id));

    if (!service) {
        return <h2>Service not found</h2>;
    }

    return (
        <div className=" mx-auto md:w-[88%]">
        <div className="flex justify-center">
            <div className=" w-full  overflow-hidden ">
            <img className="w-full md:h-[400px]" src={service.image} alt={service.name} />
            <div className="px-6 py-8">
                <div className="font-bold text-3xl mb-2 flex justify-center items-center">{service.name}</div>
                <p className="text-gray-700 text-base">{service.description}</p>
            </div>
            <div className="px-6 py-4">
            <h3 className="font-bold text-2xl m-6 flex justify-center items-center">Features</h3>
                {service.feature.map(feature => (
                    <div key={feature.id} className="flex justify-between p-6">
                        <div className=" pr-4 ">
                        <div className="font-semibold text-lg mb-2 pl-4 ">{feature.name}</div>
                            <ul className="text-gray-700 text-base list-disc ml-6">
                                {Array.isArray(feature.comment) ? (
                                    feature.comment.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))
                                ) : (
                                    <li>{feature.comment}</li>
                                )}
                            </ul>
                        </div>
                        <div className="w-[50vh] h-[50vh] m-4 overflow-hidden">
                            <img className="md:w-[50vh]  md:h-[50vh]" src={feature.image} alt={`Feature ${feature.id}`} />
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
        </div>
    );
}

export default ServicePage;
