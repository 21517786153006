import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; 
import { links } from "./NavLinks";
import logo from "../Assets/Logo.png";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [openSubmenu, setOpenSubmenu] = useState(null);

    const handleSubmenuToggle = (index) => {
        setOpenSubmenu(openSubmenu === index ? null : index);
    };

    const toggleOpenSubmenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
        setOpenSubmenu(null);
    };

    // useEffect(() => {
    //     let handler = () => {
    //         setOpenSubmenu(true);
    //     };
    //     document.addEventListener("mousedown", handler);
    //     return () => {
    //         document.removeEventListener("mousedown", handler);
    //     };
    // }, []);

    return (
        <>
            <nav className="bg-white border border-gray-100">
                <div className="mx-auto px-4 py-2">
                    <div className="flex items-center gap-40 md:mx-6 h-12">
                        <div className="flex items-center justify-between">
                            <div className="flex-shrink-0 text-white m-6">
                                <Link to="/"> 
                                    <img src={logo} alt="Logo" className="h-10 hover:scale-105" />
                                </Link>
                            </div>
                            <div className="md:hidden">
                                <button
                                    onClick={toggleOpenSubmenu}
                                    className="inline-flex  p-2 rounded-md text-gray-800 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                                >
                                    <svg className=" h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className={"hidden md:flex md:items-baseline md:space-x-2" + (isOpen ? " hidden" : "")}>
                            {links.map(({ name, link, submenu, subLinks }, index) => (
                                <div key={name} className="relative">
                                    <Link
                                        to={link}
                                        onClick={() => handleSubmenuToggle(index)}
                                        className={" text-gray-800 hover:text-red-500 px-6 py-2 text-md font-bold hover:scale-105 cursor-pointer" + (openSubmenu === index ? " text-red-700" : " text-gray-800")}
                                        // onMouseEnter={(e) => e.target.classList.add('underline')}
                                        // onMouseLeave={(e) => e.target.classList.remove('underline')}
                                    >
                                        {name}
                                    </Link>
                                    {submenu && openSubmenu === index && (
                                        <div className="absolute z-10 mt-2 w-72 bg-white rounded-md shadow-md">
                                            {subLinks.map(({ name, link }) => (
                                                <Link
                                                    key={name}
                                                    to={link}
                                                    className="block px-4 py-2 text-md text-gray-700 hover:text-blue-500 hover:scale-100"
                                                    onClick={closeMenu}
                                                >
                                                    {name}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={"md:hidden" + (isOpen ? " flex" : " hidden")}>
                    <div className="px-2 pt-2 pb-3">
                        {links.map(({ name, link, submenu, subLinks }, index) => (
                            <div key={name}>
                                <Link
                                    to={link}
                                    className={" text-gray-800 hover:text-red-500 px-6 py-2 text-md font-bold hover:scale-105 cursor-pointer" + (openSubmenu === index ? " text-red-700" : " text-gray-800")}
                                    onClick={closeMenu}
                                >
                                    {name}
                                </Link>
                                {submenu && openSubmenu === index && (
                                    <div className="ml-4">
                                        {subLinks.map(({ name, link }) => (
                                            <Link
                                                key={name}
                                                to={link}
                                                className={" text-gray-800 hover:text-red-500 px-6 py-2 text-md font-bold hover:scale-105 cursor-pointer" + (openSubmenu === index ? " text-red-700" : " text-gray-800")}
                                                onClick={closeMenu}
                                            >
                                                {name}
                                            </Link>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
