import React from 'react';
import video from '../Assets/vecteezy_colorful-glitter-lights-sparkle-squares-futuristic_27961929.mp4';
import ChartImg from "../Assets/Digital-trans-steps.png"

const Header = () => {
    return (
        <>
            <section className="min-h-screen bg-cover font-[Poppins] md:bg-top bg-center">
                <div className='w-full mb-10 '>
                    <div className="flex w-full h-auto  justify-center items-center flex-col">
                        <div className="flex w-full md:w-[88%] ">
                            <video autoPlay loop muted className='w-full object-cover md:h-[650px]' src={video} alt="Technology video" />


                            <div className=" absolute w-[80%] px-4 md:px-10  m-10 mt-10 md:mt-[300px] ">
                                <h1 className='flex w-full flex-col text-gray-100 text-md md:text-6xl font-bold'>
                                    Digital-Sync
                                    <span className='mt-2 flex flex-col gap-4 transition-opacity duration-500 ease-in-out opacity-0 md:opacity-100'>
                                        provides Products, Solutions and <span className=''>Services for Digital Transformation</span>
                                    </span>
                                </h1>

                                {/* <button className=' border text-sm md:text-xl rounded-full h-12 md:h-16 w-32 md:w-44 mt-5 md:mt-10 bg-gray-300 text-gray-900 border-gray-900 p-2 hover:bg-red-600 hover:text-black'>Discover More</button> */}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-[88%] justify-center items-center mt-2 md:mt-8 mx-4 md:mx-24 gap-3 text-gray-700 text-base'>
                        <h1 className='w-full'>We have expertise in developing digitalization solutions for Industrial Automation, Manufacturing, Utilities, Automobiles and Logistics domains. We provide end-to-end industrial grade reliable solutions for digital transformation.
                            We have various products such Industrial IoT platform, Protocol Converter, Asset Predictive Applications, Visualization and Reporting tools etc. to build reliable digital applications. We offer very unique applications and services that are industrial proven for digital transformation.
                        </h1>
                        <img className='md:w-[88%] md:h-[20%] ' src={ChartImg} alt='Chart' />
                        <div>
                            <h1 className='mb-3'>
                                Digital Transformation is a journey to connect devices, systems, and applications with each other,
                                share data with each other to develop more collaborative, observative, AI based application. There are five steps to
                                to do end-to-end digital transformation:
                            </h1>
                            <ul className='list-disc ml-6'>
                                <li className="mb-2"><span className='font-bold text-red-800'>Information Acquisitions:</span> The first step is Information Acquisition from various systems, devices, and applications. This step is very important and crucial, and inherently it has many challenges. Information Acquisition requires expertise in domains understanding, Industrial communication protocols- Modbus, OPC UA, ProfNet, HART, IEC 61850, MMS, Databases, etc. and skills to configure application to fetch the data information. At Digital Sync, we have expertise in information Acquisitions.</li>
                                <li className="mb-2"><span className='font-bold text-red-800'>Transformation and Contextualization:</span> Information from different devices, and systems are in in different format and collected at different time intervals. The transformation and contextualization of information is required to convert different format of information into the common format and co-relate the information with each other. The efficient and optimized transformation and contextualization will lead to development a very optimized solution.  Our IoT platform provides inbuilt support for transformation and contextualization of information in real-time.</li>
                                <li className="mb-2"><span className='font-bold text-red-800'>Analytics and AI:</span> For any digitalization application, the analytics and AI based information processing provides various insights of information, Key Performance Indicators (KPIs), Predictions of Events and many more. Our IoT platform provides the capability to configure, design, and implement Analytics and AI capabilities seamlessly.</li>
                                <li className="mb-2"><span className='font-bold text-red-800'>Control and Optimization:</span> The objective of digitalization is to bring more observability, control and optimization in the operations. We provide real-time industrial grade IoT platform, that is capable of bring near real time control and optimization of processes.</li>
                                <li className="mb-2"><span className='font-bold text-red-800'>Visualization and Reporting:</span> Powerful with visualization and reporting tools helps operator, engineers, and users to interpret the information fast and act on that timely. We provide powerful visualization and reporting engine that help users to monitor and visualize their plant data.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='flex flex-col w-[88%] mt-2 md:mt-8 mx-4 md:mx-24 gap-3 '>
                    <h1 className='text-4xl font-bold text-red-700'>Our Offerings</h1>
                    <h1 className='mb-3 text-gray-700 text-base'>
                        Digital Transformation is a journey to connect devices, systems, and applications with each other,
                        share data with each other to develop more collaborative, observative, AI based application. There are five steps to
                        to do end-to-end digital transformation.
                    </h1>
                </div>
            </section>
        </>
    )
}

export default Header