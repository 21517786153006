import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import Footer from './Components/Footer';
import Main from './Components/Main/Main';
import Navbar from './Components/Navbar/Navbar';
import ProductPage from './Components/Main/Products/ProductPage';
import { links } from './Components/Navbar/NavLinks';

function App() {
  return (
    <Router>
      <div className='flex flex-col justify-between'>
        <Navbar />
        <Main/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
