// import Footer from '../Footer;'
import ProductData from '../Main/Products/ProductData';
import SolutionData from '../Main/Solutions/SolutionData';
import ServiceData from '../Main/Services/ServiceData';
// import ProductPage from './Products/ProductPage';

export const links = [
    { name: "Home", link: "/" },
    {
        name: "Products",
        submenu: true,
        // subLinks: [
        //     { name: "Edgematic IoT Platform", link: "/" },
        //     { name: "Connectors", link: "/" },
        //     { name: "OPC UA Servers", link: "/" },
        //     { name: "Dashboard Applications", link: "/" },
        //     { name: "Protocol Converters", link: "/" },
        //     { name: "Machine Predictive Models", link: "/" },
        //     { name: "Notificiation and Reporting tools", link: "/" },
        // ],
        subLinks: ProductData.map(product => ({
            name: product.name,
            link: `/product/${product.id} `
        })),
    },
    {
        name: "Solutions",
        submenu: true,
        // subLinks: [
        //     { name: "Engergy Management", link: "/" },
        //     { name: "Water Management", link: "/" },
        //     { name: "Fleet Management", link: "/" },
        //     { name: "Warehouse Management", link: "/" },
        //     { name: "Asset Predictive Maintenance", link: "/" },
        //     { name: "OT and IT Data Logger", link: "/" },
        //     { name: "Dashboard and Reporting", link: "/" },
        // ],
        subLinks: SolutionData.map(solution => ({
            name: solution.name,
            link: `/solution/${solution.id}`
        })),
    },
    {
        name: "Services",
        submenu: true,
        // subLinks: [
        //     { name: "Digital Transformation", link: "/" },
        //     { name: "5G Based Solution Development for OT", link: "/" },
        //     { name: "Consultancy", link: "/" },
        //     { name: "Technology Trainings", link: "/" },
        //     { name: "Product Innovation Services", link: "/" },
        //     { name: "Reserach and Development", link: "/" },
        // ],
        subLinks: ServiceData.map(service => ({
            name: service.name,
            link: `/service/${service.id}`
        })),
    },
    { name: "Use Cases", link: "/" },
    { name: "Career", link: "/" },
    { name: "Contact us", link: "/footer" },
]