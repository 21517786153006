import FeatureImg from '../../Assets/download.jpeg'
const servicesData = [{
        id: 1,
        name: 'Private 5G based Solution Development for OT',
        description: 'Private 5G provides high reliability, low latency, and high throughput wireless communication infrastructure, which is very suitable for OT networks in manufacturing, Industrial Automation, Warehouse management likewise applications. We have expertise in developing end-to-end OT and IT solutions on private 5G network, interfacing non-5G devices and system with 5G networks. ',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 2,
        name: 'Digital Transformation Solution Development',
        description: 'Digital Transformation is a journey, and this should be done in phased wise manner. At every phase, the value of digital transformation should be analysed before proceeding the next phase. We have expertise in understanding your plant operations and provide the details of Digital Transformation Solution Development plan and execute in the phased wise manner.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 3,
        name: 'Consultancy',
        description: 'We offer technical consultancy services for developing Operational Technology application and services, performing cyber security analysis, Threat analysis, Solution Architecting, Test Plan development. We have expertise in Industrial IoT solution development, Industrial Communication Protocols, Cyber Security.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 4,
        name: 'Technology Trainings',
        description: 'We provide corporate training on various technologies like, docker, Kubernetes, OPC UA, OPC UA Pub/Sub, IoT, Industrial Communication Protocols, Cyber Security etc.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 5,
        name: 'Research and Development',
        description: 'Technology is changing at rapid pace. We do help our clients to do Research and Development for developing new products. We do competitors analysis, Fast Proof of Concept Development, Publishing a research papers, product innovation workshops. ',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 6,
        name: 'Product Invonation Services',
        description: 'For a company it is very important to do define new product and services to capture new market opportunities, we help our clients to develop innovative products and services, and help them to protect their solution by filing the patents. We help them to write patent applications and filing the same.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    // {
    //     id: 7,
    //     name: 'Product 7',
    //     description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
    //     image: 'https://picsum.photos/200/300',
    //     review: [
    //         { id: 1, comment: "Review 1 for Product 1" },
    //         { id: 2, comment: "Review 2 for Product 1" },
    //     ],
    // },
];

export default servicesData;