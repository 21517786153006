import FeatureImg from '../../Assets/download.jpeg'
const solutionsData = [{
        id: 1,
        name: 'Energy Management Platform',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dignissim velit eu nisl tempor, sit amet consequat ligula dignissim.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 2,
        name: 'Water Management Platform',
        description: 'Sed et enim nec nulla maximus efficitur. Curabitur ac nisi eu arcu cursus lacinia.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 3,
        name: 'Asset Predictive Maintenance',
        description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut tincidunt, neque eu lacinia placerat, justo odio dapibus turpis.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 4,
        name: 'OPC UA Test Server',
        description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 5,
        name: 'Data Acquistions',
        description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 6,
        name: 'Visualization and Reporting',
        description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    // {
    //     id: 7,
    //     name: 'Product 7',
    //     description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
    //     image: 'https://picsum.photos/200/300',
    //     review: [
    //         { id: 1, comment: "Review 1 for Product 1" },
    //         { id: 2, comment: "Review 2 for Product 1" },
    //     ],
    // },
];

export default solutionsData;