import React, { useState } from 'react';
import productsData from './ProductData';
import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg border border-gray-200 hover:scale-105">
        <img className="w-full h-[200px] rounded-md" src={product.image} alt={product.name} />
        <div className="px-6 py-4">
            <Link to={`/product/${product.id}`}  className="font-bold text-xl mb-2">{product.name}</Link>
            <p className="text-gray-700 text-base line-clamp-4">{product.description}</p>
            <Link to={`/product/${product.id}`} className="flex mt-2 text-sm text-blue-500 hover:text-blue-700 items-end hover:underline">
                Read More
            </Link>
        </div>
        </div>
    );
    };

    const ProductsSection = () => {
    const [showAll, setShowAll] = useState(false);

    const productsToDisplay = showAll ? productsData : productsData.slice(0, 4);

    return (
        <section className="container px-4 md:px-28 mx-auto my-8">
            <div className="text-3xl font-bold text-red-700 mb-4 md:mb-8">Products <div className="w-full h-0.5 bg-gray-300 mt-2"></div></div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {productsToDisplay.map(product => (
            <ProductCard key={product.id} product={product} />
            ))}
        </div>
        {!showAll ? (
        <button onClick={() => setShowAll(true)} className="block mx-auto mt-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            See All Products
            </button>
        ) : (
            <button onClick={() => setShowAll(false)} className="block mx-auto mt-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            Hide Products
        </button>
        )}
        </section>
    );
};

export default ProductsSection;

