import React, { useState } from 'react';
import solutionsData from './SolutionData';
import { Link } from 'react-router-dom';

const SolutionCard = ({ solution }) => {
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg border border-gray-200 hover:scale-105">
            <img className="w-full h-[200px] rounded-md" src={solution.image} alt={solution.name} />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{solution.name}</div>
                <p className="text-gray-700 text-base line-clamp-4">{solution.description}</p>
                <Link to={`/solution/${solution.id}`} className="flex mt-2 text-sm text-blue-500 hover:text-blue-700 items-end hover:underline">
                    Read More
                </Link>
            </div>
        </div>
    );
};

    const SolutionsSection = () => {
    const [showAll, setShowAll] = useState(false);

    const solutionsToDisplay = showAll ? solutionsData : solutionsData.slice(0, 4);

    return (
        <section className="container px-4 md:px-28 mx-auto my-8">
            <div className="text-3xl font-bold text-red-700 mb-4 md:mb-8">Solutions <div className="w-full h-0.5 bg-gray-300 mt-2"></div></div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {solutionsToDisplay.map(solution => (
            <SolutionCard key={solution.id} solution={solution} />
            ))}
        </div>
        {!showAll ? (
        <button onClick={() => setShowAll(true)} className="block mx-auto mt-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            See All Solutions
            </button>
        ) : (
            <button onClick={() => setShowAll(false)} className="block mx-auto mt-8 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
            Hide Solutions
        </button>
        )}
        </section>
    );
};

export default SolutionsSection;

