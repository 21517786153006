import React from "react";
import {
    FaFacebook,
    FaGithub,
    FaInstagram,
    FaTwitter,
    FaLinkedin,
    } from "react-icons/fa";

    const Footer = () => {
    return (
        <div className="w-full mt-24 bg-slate-900 text-gray-300 py-4 px-2">
        <div className="max-w-[1400px] mx-auto border-b-2 border-gray-600 py-8">
            <div className="px-12 flex flex-wrap justify-between">
                <div className="w-full sm:w-auto">
                    <h6 className="font-bold uppercase pt-2">Address</h6>
                    <ul>
                        <li className="py-1 text-gray-500 hover:text-white">Digital Sync Solutions and Services</li>
                        <li className="py-1 text-gray-500 hover:text-white">ITPL Road, Whitefield, Bengaluru- 560037
                        </li>
                        <li className="py-1 text-gray-500 hover:text-white">Email-info@digital-sync.com</li>
                        <li className="py-1 text-gray-500 hover:text-white">Phone:+91 72044-35361, 080-47565585 </li>
                    </ul>
                </div>
                {/* <div className="w-full sm:w-auto">
                    <h6 className="font-bold uppercase pt-2">Support</h6>
                    <ul>
                        <li className="py-1 text-gray-500 hover:text-white">Pricing</li>
                        <li className="py-1 text-gray-500 hover:text-white">Documentation</li>
                        <li className="py-1 text-gray-500 hover:text-white">Guides</li>
                    </ul>
                </div> */}
                {/* <div className="w-full sm:w-auto">
                    <h6 className="font-bold uppercase pt-2">Company</h6>
                    <ul>
                        <li className="py-1 text-gray-500 hover:text-white">Products</li>
                        <li className="py-1 text-gray-500 hover:text-white">Blogs</li>
                        <li className="py-1 text-gray-500 hover:text-white">Jobs</li>
                        <li className="py-1 text-gray-500 hover:text-white">Partners</li>
                    </ul>
                </div> */}
                {/* <div className="w-full sm:w-auto">
                    <h6 className="font-bold uppercase pt-2">Legal</h6>
                    <ul>
                        <li className="py-1 text-gray-500 hover:text-white">Privacy</li>
                        <li className="py-1 text-gray-500 hover:text-white">Policies</li>
                        <li className="py-1 text-gray-500 hover:text-white">Terms</li>
                        <li className="py-1 text-gray-500 hover:text-white">Conditions</li>
                    </ul>
                </div> */}
                <div className="col-span-2 pt-8 md:pt-2">
                    {/* <p className="font-bold uppercase">Social Media</p> */}
                    {/* <p className="py-2">
                        The latest news, articles, and resources, sent to your inbox weekly.
                    </p>
                    <form className="flex flex-col sm:flex-row">
                        <input
                        className="w-full p-2 mr-4 rounded-md mb-4"
                        type="email"
                        placeholder="Enter email.."
                        />
                        <button className="p-2 border rounded-md border-gray-100 mb-4 hover:bg-red-600">Subscribe</button>
                    </form> */}
                    {/* <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
                        <a href="#" className=" hover:text-blue-400 hover:scale-105"><FaFacebook/></a>
                        <a href="#" className=" hover:text-pink-500 hover:scale-105"><FaInstagram/></a>
                        <a href="#" className=" hover:text-blue-400 hover:scale-105"><FaTwitter/></a>
                        <a href="#" className=" hover:text-blue-600 hover:scale-105"><FaLinkedin/></a>
                        <a href="#" className=" hover:text-gray-300 hover:scale-105"><FaGithub/></a>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="flex flex-col px-2 py-2 mx-auto justify-center sm:flex-row text-center text-gray-500">
            <p className="py-2">2024 Digital Sync, All rights reserved</p>
            
        </div>
        </div>
    );
};

export default Footer;