import FeatureImg from '../../Assets/download.jpeg';
import DashboardImg from '../../Assets/ProductsImage/DashboardApplication.png';
import EdagematicImg from '../../Assets/ProductsImage/Edgematic.png';
import ConnectorsImg from '../../Assets/ProductsImage/Connectors.png';
import OPCImg from '../../Assets/ProductsImage/OPC.png';
import NotificationReportImg from '../../Assets/ProductsImage/Notification&Reporting.png'

const productsData = [{
        id: 1,
        name: 'Edgematic- Industrial Grade IoT Platform',
        description: 'Edgematic is our Industrial Grade IoT Platform, which offers very unique features- Cloud agnostics, remote management, AI/ML enabled, rule engine, visualization and reportings..',
        image: EdagematicImg,
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 2,
        name: 'Connectors for Devices, Systems, and Applications',
        description: 'We have connectors to connect with various devices, systems, and application seamlessly to fetch the information. We have generic connectors such as for OPC UA, Modbus, OPC Da, CANBUS etc. and device specific connectors. Our Connector can be deployment and configured on the Edge Gateway. ',
        image: ConnectorsImg,
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 3,
        name: 'OPC UA Server',
        description: 'We have expertise in developing OPC UA Server, and various OPC UA based applications,like OPC UA server for testing, OPC UA PubSub, OPC UA Test Server, OPC UA Clinet, thin client for embedded application, OPC UA Server connector of Edge Gateway',
        image: OPCImg,
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["- Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 4,
        name: 'Dashboard Applications',
        description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
        image: DashboardImg,
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 5,
        name: 'Notification and Reporting',
        description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
        image: NotificationReportImg,
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    {
        id: 6,
        name: 'Protocol Converters',
        description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
        image: 'https://picsum.photos/200/300',
        feature: [{
                id: 1,
                name: "Feature 1",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
            {
                id: 2,
                name: "Feature 2",
                comment: ["Cloud agnostics", "Remote management AI/ML enabled", "Rule engine Visualization and reportings"],
                image: FeatureImg
            },
        ],
    },
    // {
    //     id: 7,
    //     name: 'Product 7',
    //     description: 'Fusce vel lorem ullamcorper, vestibulum quam vitae, fermentum ipsum. Integer mattis sit amet nunc non ultricies.',
    //     image: 'https://picsum.photos/200/300',
    //     review: [
    //         { id: 1, comment: "Review 1 for Product 1" },
    //         { id: 2, comment: "Review 2 for Product 1" },
    //     ],
    // },
];

export default productsData;